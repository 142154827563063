import React from "react"
import styled from "styled-components"
import Hero from "../components/Hero"
import Button from "../components/Button"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import LargeHeading from "../components/LargeHeading"
import SectionHeading from "../components/SectionHeading"
import ThreeColumns from "../components/ThreeColumns"
import SectionedContent from "../components/SectionedContent"
import background from "../images/homeBG.jpg"
import smiling from "../images/smiling.jpg"

const StyledNotice = styled.div`
  width: 50%;
  color: white;
  padding: 20px;
  line-height: 1.4;
  margin-bottom: 20px;
  border-radius: 12px;
  background: #bb1d42;
`

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Home"
        description="Sonder is on a mission to end period poverty and provide women all over
            the world with the basic care they deserve"
      />
      <Hero
        header="Fighting period poverty with the menstrual cup"
        subHeader="1 Menstrual cup = 600 days of safe, dignified & life changing menstruation"
        background={background}
      />
      <Container size="m">
        <div id="period-poverty">
          <LargeHeading>
            We’re on a mission to end period poverty and provide women all over
            the world with the basic care they deserve
          </LargeHeading>
          <SectionHeading title="What is period poverty?">
            Period poverty is the lack of access to education, social support
            and facilities for dignified menstruation. The following are vital
            for period prosperity.
          </SectionHeading>
          <ThreeColumns
            columns={[
              {
                title: "Education",
                content:
                  "Young girls lack access to basic education of normal anatomy, menstruation and it's management. In the UK 137,700 girls miss school during their period.",
              },
              {
                title: "Environment",
                content:
                  "Areas affected by period poverty associate stigma and shame to menstruation. Women and girls lack the dignity and support they need in their community.  In Kenya 2 out of 3 women in rural areas exchange pads for favours with sexual partners.",
              },
              {
                title: "Essentials",
                content:
                  "Women who have little access to sanitary products and basic facilities for hygienic menstruation. In India only 12% of 355 million women can afford sanitary protection.",
              },
            ]}
          />
        </div>
      </Container>
      <div id="kenya-2020">
        <SectionedContent heading="Kenya 2020" background={smiling}>
          <StyledNotice>
            Unfortunately, due to Covid 19 pandemic our Kenya 2020 mission has
            temporarily been postponed. We hope to recommence this mission when
            safe to do so as per government guidelines.
          </StyledNotice>
          <p>
            This year we aim to travel to Kenya with a small group of newly
            graduated doctors who are passionate about women’s health. Our goal
            is to provide local women with information and education at each
            destination.
            <br />
            <br />
            Period poverty is prevalent worldwide. Kenya was chosen for our
            first mission due to a Kenyan school girl who committed suicide due
            to period shaming in September 2019. This was why our passion lead
            us to here. It is recorded that, in 2018, 10% of girls in Subsaharan
            Africa missed school during their periods. 2 out of 3 pad users in
            rural Kenya receive them from sexual partners, some of these females
            as young as 13 years. In Kenya, 1 in 4 girls don’t associate
            menstruation with pregnancy. Education is vital.
          </p>
          <p>
            The sole criteria of this mission is that each community we visit
            must have running water to ensure safety of the cup. The
            distribution of the menstrual cups is extremely important, however,
            it is the education of female menstruation and the information about
            how to use these cups that we believe is vital to empower these
            women. We endeavor to educate these women to then educate and
            empower others. These mothers, sisters, aunts, daughters and nieces
            deserve dignity every day.
          </p>
        </SectionedContent>
      </div>
      <Container size="m">
        <SectionHeading title="One third of the female population are affected by period poverty">
          Our aim is to empower women and improve the physical and mental health
          of the areas suffering from period poverty.
          <br />
          <Button as="a" href="/get-involved" css="margin-top: 40px;">
            Get Involved
          </Button>
        </SectionHeading>
      </Container>
    </Layout>
  )
}

export default IndexPage
