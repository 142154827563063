import React from "react"
import styled from "styled-components"

const StyledLargeHeading = styled.h1`
  color: #430028;
  font-size: 31px;
  font-weight: 400;
  max-width: 840px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.04em;
  margin: 0 auto 80px auto;
`

const LargeHeading = ({ children }) => {
  return <StyledLargeHeading>{children}</StyledLargeHeading>
}

export default LargeHeading
