import React from "react"
import styled from "styled-components"
import Container, { StyledContainer } from "../Container"
import Button from "../Button"
import wave from "./wave.svg"

const StyledHero = styled.div`
  color: white;
  height: 680px;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 100px;
  justify-content: left;
  background-size: cover;
  background-position: center;

  &::before {
    left: 0;
    right: 0;
    bottom: -1px;
    content: "";
    height: 40px;
    position: absolute;
    pointer-events: none;
    background: url(${wave}) center top no-repeat;
    background-size: 100% 100%;
  }

  ${StyledContainer} {
    @media (max-width: 800px) {
      text-align: center;
    }
  }
`

const StyledHeading = styled.h1`
  width: 55%;
  font-size: 50px;
  margin-bottom: 20px;
  letter-spacing: -0.075rem;

  @media (max-width: 800px) {
    width: 100%;
  }
`

const StyledSubHeading = styled.p`
  width: 50%;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 50px;

  @media (max-width: 800px) {
    width: 100%;
  }
`

const Hero = ({ header, subHeader, background }) => (
  <StyledHero
    css={`
      background-image: url(${background});
    `}
  >
    <Container>
      <StyledHeading>{header}</StyledHeading>
      <StyledSubHeading>{subHeader}</StyledSubHeading>
      <Button as="a" href="/get-involved">
        Get Involved
      </Button>
    </Container>
  </StyledHero>
)

export default Hero
