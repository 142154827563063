import React from "react"
import styled from "styled-components"
import Container from "../Container"
import wave from "./wave.svg"

const StyledSectionedContent = styled.div`
  position: relative;
  margin-bottom: 80px;
  padding: 120px 0 100px 0;
  background-color: #e85679;

  &::before {
    left: 0;
    right: 0;
    z-index: 10;
    bottom: -1px;
    content: "";
    height: 40px;
    position: absolute;
    pointer-events: none;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${wave});
  }

  &::after {
    left: 0;
    right: 0;
    top: -1px;
    z-index: 10;
    content: "";
    height: 40px;
    position: absolute;
    pointer-events: none;
    transform: rotate(180deg);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${wave});
  }
`

const Background = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  opacity: 0.15;
  position: absolute;
  background-size: cover;
  background-position: center;
`

const Heading = styled.h2`
  color: white;
  font-size: 50px;
  margin-bottom: 30px;
  letter-spacing: -1px;
`

const Columns = styled.div`
  color: white;
  display: flex;
  margin: 0 -20px;
  flex-wrap: wrap;

  p {
    width: 50%;
    margin: 0 20px;
    font-size: 17px;
    line-height: 26px;
  }

  @media (max-width: 800px) {
    p {
      width: 100%;
    }
  }
`

const SectionedContent = ({ background, heading, children }) => {
  return (
    <StyledSectionedContent>
      <Background
        style={{
          backgroundImage: `url(${background})`,
        }}
      />
      <div style={{ zIndex: 1, position: "relative" }}>
        <Container>
          <Heading>{heading}</Heading>
          <Columns>{children}</Columns>
        </Container>
      </div>
    </StyledSectionedContent>
  )
}

export default SectionedContent
